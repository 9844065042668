import 'core-js/es/map';
import 'core-js/es/set';

import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/object/assign';

// TODO: Performance - only import this when Map component is required
import 'core-js/features/symbol';
import 'core-js/features/array/from';
import 'core-js/features/number/is-finite';
import 'core-js/features/reflect';
import 'core-js/features/symbol/iterator';

if (typeof window !== 'undefined') {
  require('intersection-observer');
}

// TODO: Performance - only import this when Map component is required
/* tslint:disable */
Math.tanh =
  Math.tanh ||
  function (x) {
    const a = Math.exp(+x),
      b = Math.exp(-x);
    //eslint-disable-next-line eqeqeq
    return a == Infinity ? 1 : b == Infinity ? -1 : (a - b) / (a + b);
  };

Math.cosh =
  Math.cosh ||
  function (x) {
    const y = Math.exp(x);
    return (y + 1 / y) / 2;
  };

Math.sinh =
  Math.sinh ||
  function (x) {
    const y = Math.exp(x);
    return (y - 1 / y) / 2;
  };

Math.log2 =
  Math.log2 ||
  function (x) {
    return Math.log(x) * Math.LOG2E;
  };
/* tslint:enable */
